import React from "react";

const TechStack = () => (
  <div className="skills">
    <p>Tech Stack</p>
    <ul>
      {[
        { src: "/tech-stack/php.svg", title: "Laravel / PHP" },
        { src: "/tech-stack/react.svg", title: "React" },
        { src: "/tech-stack/typescript.svg", title: "Typescript" },
        { src: "/tech-stack/next-js.svg", title: "Next JS" },
        { src: "/tech-stack/git-icon.svg", title: "Git" },
        { src: "/tech-stack/tailwind.svg", title: "Tailwind" },
      ].map((item, index) => (
        <li key={index}>
          <img
            src={item.src}
            title={item.title}
            alt={`skill-icon-${item.title.toLowerCase()}`}
          />
        </li>
      ))}
    </ul>
  </div>
);

export default TechStack;