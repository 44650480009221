import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <div><Toaster/></div>
        <App/>
    </React.StrictMode>
);
