interface ISkillCard {
    title: string;
    description: string;
    image: string;
    href?: string;
}

export default function CertificationCard(props: ISkillCard) {
    return (
        <>
            <a className="certification-card" href={props.href} target="_blank">
                <img src={props.image} alt={props.title}/>
                <p>{props.title}</p>
                <p>{props.description}</p>
            </a>
        </>
    );
}
