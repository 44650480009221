import ProjectCard from "../components/projectCard";
import Nav from "../shared/Nav";
import {Title} from "../shared/Title";

export default function Projects() {
    return (
        <>
            <header>
                <Title>Projects</Title>
                <Nav/>
            </header>

            <section id="projects" className="projects-container slow-animate-in">
                <ProjectCard
                    title="Stylesphere"
                    text="Stylesphere is a sophisticated ecommerce platform that redefines fashion discovery and self-expression. With its intuitive interface and a curated array of elegant selections, Stylesphere empowers you to explore your unique style effortlessly. From everyday staples to exquisite couture."
                    image="./StyleSphere.png"
                    technologies="Next Typescript Tailwind"
                    right
                    live="https://stylesphere.iolivera.dev/"
                    code="https://github.com/iolivera1/StyleSphere"
                />
                <ProjectCard
                    title="StyleSphere - Admin Panel"
                    text="The StyleSphere Admin Panel offers powerful control over your fashion empire. Manage products, categories, colors, billboards and more."
                    image="./StyleSphere-admin.png"
                    technologies="Next Typescript Tailwind Prisma NeonDB Clerk Cloudinary shadcn"
                    live="https://adminsphere.iolivera.dev/"
                    code="https://github.com/iolivera1/StyleSphere-Admin-Panel"
                    credentials
                />
            </section>

            <section id="contribution" className="projects-container slow-animate-in">
                <h2>Contributions</h2>
                <ProjectCard
                    title="Ceibal"
                    text="Ceibal is a platform that offers educational resources for students and teachers. It also provides a blog section where users can read about the latest educational trends."
                    image="/contributions/Ceibal.png"
                    technologies="PHP React Wordpress Gutenberg Tailwind Custom-Blocks"
                    live="https://ceibal.edu.uy/"
                    right
                />
                <ProjectCard
                    title="La oferta Irresistible"
                    text="La Oferta Irresistible is a platform that offers discounts on various products. Users can browse through the available offers and purchase them."
                    image="/contributions/Loi.png"
                    technologies="PHP React Tailwind Algolia MySql"
                    live="https://loi.com.uy/"
                />
                <ProjectCard
                    title="Conaprole Foodies"
                    text="Conaprole Foodies is a platform that allows users to share their recipes and discover new ones. It also offers a blog section where users can read about the latest food trends."
                    image="/contributions/Conaprole.jpg"
                    technologies="PHP MySQL"
                    live="https://foodie.conaprole.uy/"
                    right
                />
                <ProjectCard
                    title="Toyota Paralympics"
                    text="Toyota Paralympics is a platform that offers information about the Uruguayan Paralympics athletes. Users can read about the athletes, the events, and download images."
                    image="/contributions/Toyota.png"
                    technologies="PHP MySQL"
                    live="https://www.sponsordelasuperacion.uy/"
                />
                <p>And more projects are coming... </p>
            </section>
        </>
    );
}
